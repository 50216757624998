

















































































































import Component, { mixins } from 'vue-class-component';
import WidgetMixins from '../../../mixins/WidgetMixins';
import DialogMixins from '../../../mixins/DialogMixins';
import { currencyFormat, loadView, loadWidget } from '@/utils/helpers';
import { namespace } from 'vuex-class';
import { IProduct, IStore, IUser, IUSSDServiceCodes } from '@/types/types';
import timestamp from '@/filters/timestamp';
import mediumZoom from 'medium-zoom';
import { Watch } from 'vue-property-decorator';

const ussdCodesModule = namespace('ussdCodes');
const authModule = namespace('auth');
@Component({
  name: 'USSDCodesIndex',
  components: {
    CreateUSSDCodeDialog: loadView('pages/ussd-codes/dialog/CreateUSSDCodeDialog'),
    EditUSSDCodeDialog: loadView('pages/ussd-codes/dialog/EditUSSDCodeDialog'),
    InternetConnection: loadWidget('pages/InternetConnection'),
    ToolBar: loadWidget('widgets/ToolBar'),
    SnackBar: loadWidget('widgets/SnackBar'),
  },
  filters: {
    currencyFormat,
    timestamp,
  },
})
export default class USSDCodesIndex extends mixins(WidgetMixins, DialogMixins) {
  @ussdCodesModule.Getter('getAllUssdServiceCodes') ussdServiceCodes!: IUSSDServiceCodes[];
  @authModule.Getter('getUserDetails') details!: IUser & IStore;
  menu: boolean = false;
  headers: Array<{ text: string; value: string }> = [
    {
      text: '',
      value: 'logo',
    },
    {
      text: 'Consumer',
      value: 'consumer',
    },
    {
      text: 'Code',
      value: 'code',
    },
    {
      text: 'API Url',
      value: 'apiUrl',
    },
    {
      text: 'Status',
      value: 'status',
    },
    {
      text: 'Actions',
      value: 'actions',
    },
  ];

  addUSSDServiceCode(payload: Pick<IUSSDServiceCodes, 'apiUrl' | 'code' | 'consumer' | 'description'>): void {
    this.$store.dispatch('ussdCodes/create', payload);
  }
  editUSSDServiceCode(payload: Pick<IUSSDServiceCodes, 'apiUrl' | 'code' | 'consumer' | 'description' | 'id'>): void {
    this.$store.dispatch('ussdCodes/update', payload);
  }
  created(): void {
    this.$store.dispatch('ussdCodes/list');
    this.$store.dispatch('auth/me');
  }
  mounted(): void {
    mediumZoom('.productPreview');
  }
}
